interface IEnvironment {
  production: IEnvironmentEntry;
  staging: IEnvironmentEntry;
}

interface IEnvironmentEntry {
  GA_KEY?: string;
  CHANNELIO_PLUGIN_KEY: string;
  homepageUrl: string;
  dashboardUrl: string;
}

const ENV_TYPE = process.env.GATSBY_ENV || "staging";

// tslint:disable:object-literal-sort-keys
const byEnv: IEnvironment = {
  production: {
    GA_KEY: "UA-126138188-3",
    CHANNELIO_PLUGIN_KEY: `0860685a-ec6b-4dfc-96e1-05d25fb83a24`,
    homepageUrl: "https://henesis.io/",
    dashboardUrl: "https://app.wallet.henesis.io/",
  },
  staging: {
    GA_KEY: "UA-126138188-3",
    CHANNELIO_PLUGIN_KEY: `0860685a-ec6b-4dfc-96e1-05d25fb83a24`,
    homepageUrl: "https://henesis.io/",
    dashboardUrl: "https://app.dev.wallet.henesis.io/",
  },
};

export const isProduction = () => ENV_TYPE === "production";

export const env = byEnv[ENV_TYPE as keyof IEnvironment] || {};
