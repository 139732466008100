module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Henesis: The Most Secure Crypto Wallet API","short_name":"Henesis: The Most Secure Crypto Wallet API","start_url":"/","background_color":"#fff","theme_color":"#060607","icon":"src/images/logo/logo.png","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d1b55d9da25b562c5d53c9104f40da16"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
