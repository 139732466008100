// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-ko-tsx": () => import("./../../../src/pages/index.ko.tsx" /* webpackChunkName: "component---src-pages-index-ko-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-product-en-tsx": () => import("./../../../src/pages/product.en.tsx" /* webpackChunkName: "component---src-pages-product-en-tsx" */),
  "component---src-pages-product-ko-tsx": () => import("./../../../src/pages/product.ko.tsx" /* webpackChunkName: "component---src-pages-product-ko-tsx" */)
}

